import React from "react";
import { ReferralModal } from "./referralModal";

const ReferralModalPartner = () => {
  const { Title, Text, CloseButton } = ReferralModal;
  return (
    <ReferralModal referralType="partner">
      <Title>Welcome, friend! 👋</Title>
      <Text>
        We're so excited you were referred to Mangomint, and we can't wait to show you all the reasons we were
        recommended! If you are ready to try us out, we will give you two months of Mangomint, pair you up with an
        amazing white glove onboarding specialist, and transfer your data over for you —{" "}
        <i>
          all for <strong>free</strong>
        </i>{" "}
        — just for being a friend of a friend!
      </Text>
      <CloseButton>Sounds good!</CloseButton>
    </ReferralModal>
  );
};

export { ReferralModalPartner };
