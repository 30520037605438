import { useState } from "react";
import { formatISO } from "date-fns";
import { trackEvent } from "@utils/analytics";

const REFERRAL_DATA_LS_KEY = "Mt.referralData";

const Storage = {
  get: () => catchLocalStorage(() => JSON.parse(localStorage?.getItem(REFERRAL_DATA_LS_KEY))),
  set: (value) => catchLocalStorage(() => localStorage?.setItem(REFERRAL_DATA_LS_KEY, JSON.stringify(value))),
};

const catchLocalStorage = (fn) => {
  if (typeof window !== "undefined" && window?.localStorage) {
    try {
      return fn();
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
};

const trackReferralEvent = (event, referralData) => {
  const allEventPropsByType = {
    customer: {
      "Customer.io Id": referralData?.customer?.id || referralData?.cioId,
      "Referral first captured at": referralData?.customer?.capturedAt || referralData?.capturedAt,
    },
    partner: {
      "Partner Id": referralData?.partner?.id,
      "Partner Referral first captured at": referralData?.partner?.capturedAt,
    },
    tsb: {
      "TSB Referral": referralData?.tsb?.id,
      "TSB Referral first captured at": referralData?.tsb?.capturedAt,
    },
  };

  const referralTypes = Object.keys(referralData).filter((t) => referralData[t]?.id);
  const typeProps = referralTypes.map((t) => allEventPropsByType[t]);
  const eventProps = Object.assign({}, ...typeProps);

  trackEvent(`Referral: ${event}`, eventProps);
};

const useReferralData = () => {
  const formatReferral = (type, id, data) => {
    if (!type || !id) return null; // Invalid referral

    return {
      [type]: {
        id,
        capturedAt: formatISO(new Date()),
        ...data,
      },
    };
  };

  const captureReferral = (type, id, data) => {
    if (id === referralData?.[type]?.id) return null; // Already stored
    const referral = formatReferral(type, id, data);

    if (referral) {
      const updatedReferralData = Object.assign(referralData, referral);
      Storage.set(updatedReferralData);
      setReferralData(updatedReferralData);
      trackReferralEvent("Captured", referral);
    }
  };

  const [referralData, setReferralData] = useState(() => {
    const initialData = Storage.get() || {};

    const { cioId, capturedAt, ...storedReferrals } = initialData;
    const legacyReferral = formatReferral("customer", cioId, { capturedAt });

    const convertedReferralData = Object.assign(storedReferrals, legacyReferral);
    Storage.set(convertedReferralData);
    return convertedReferralData;
  });

  return {
    referralData,
    captureReferral,
    trackReferralTrialSignup: () => trackReferralEvent("Trial Signup", referralData),
  };
};

export { useReferralData };
