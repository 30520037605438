import React from "react";

export const IconClose = ({ className, width, height, ...props }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width || "18px"}
      height={height || "18px"}
      {...props}
    >
      <path d="M1 1L17 17" stroke="#A3A5AF" strokeWidth="2" />
      <path d="M1 17L17 1" stroke="#A3A5AF" strokeWidth="2" />
    </svg>
  );
};
