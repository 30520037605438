import React, { useEffect, createContext, useContext } from "react";
import * as c from "./referralModal.module.scss";
import { Button } from "@components/shared/redesign/button";
import useModal from "@hooks/useModal";
import { useReferralRoute } from "@hooks/referral/useReferralRoute";

const ReferralModalContext = createContext();

const ReferralModal = ({ referralType, children, ...props }) => {
  const { Modal, openModal, closeModal, isModalOpen } = useModal({ backgroundColor: "grey" });
  const { isReferralRoute } = useReferralRoute({ referralType });

  useEffect(() => {
    if (isReferralRoute) {
      openModal();
    }
  }, [isReferralRoute]);

  return (
    <>
      {isModalOpen && (
        <Modal {...props}>
          <div className={c.container}>
            <ReferralModalContext.Provider value={{ closeModal }}>{children}</ReferralModalContext.Provider>
          </div>
        </Modal>
      )}
    </>
  );
};

const Title = ({ children }) => <h2 className={c.title}>{children}</h2>;
const Text = ({ children }) => <p className={c.text}>{children}</p>;
const CloseButton = ({ children }) => {
  const { closeModal } = useContext(ReferralModalContext);
  return (
    <Button asHtmlButton={true} className={c.closeButton} onClick={(e) => closeModal(e)}>
      {children}
    </Button>
  );
};

ReferralModal.Title = Title;
ReferralModal.Text = Text;
ReferralModal.CloseButton = CloseButton;

export { ReferralModal };
