import { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useReferralData } from "@hooks/referral/useReferralData";
import { useLocation } from "@reach/router";
import useSSR from "use-ssr";

const REFERRAL_DATA_QUERY_PARAM_CIOID = "r";
const REFERRAL_DATA_QUERY_PARAM_TSB = "utm_source";

// Given a path in the form of /referral/:id/*, returns :id
// ex: /referral/northone/ => northone
function getPartnerId(pathname) {
  const referralBasePath = /^\/referral\/([^\/]+)/;
  return pathname.match(referralBasePath)?.[1] ?? null;
}

const useReferralRoute = ({ referralType }) => {
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);

  const { partnerPages } = useStaticQuery(
    graphql`
      query {
        partnerPages: allSitePage(filter: { path: { glob: "/referral/*" } }) {
          nodes {
            path
          }
        }
      }
    `
  );
  const partnerPaths = partnerPages.nodes.flatMap(({ path }) => path);
  const isPartnerPath = partnerPaths.includes(pathname);

  const capture = {
    partner: isPartnerPath && getPartnerId(pathname),
    customer: params.get(REFERRAL_DATA_QUERY_PARAM_CIOID),
    tsb: params.get(REFERRAL_DATA_QUERY_PARAM_TSB) === "thesalonbusiness",
  };

  const { isBrowser } = useSSR();
  const { captureReferral } = useReferralData();

  const capturedId = capture[referralType];
  const isReferralRoute = Boolean(capturedId) && isBrowser;

  useEffect(() => {
    if (isReferralRoute) {
      captureReferral(referralType, capturedId);
    }
  }, [isReferralRoute, captureReferral, referralType, capturedId]);

  return { isReferralRoute };
};

export { useReferralRoute };
