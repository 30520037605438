import React from "react";
import { ReferralModal } from "./referralModal";

const ReferralModalTSB = () => {
  const { Title, Text, CloseButton } = ReferralModal;
  return (
    <ReferralModal referralType="tsb">
      <Title>Hey The Salon Business friend!  👋</Title>
      <Text>
        We’re so excited to see a member of The Salon Business community here! Take a look around and start a trial when
        you’re ready. As you were referred by The Salon Business, you’ll get two months with Mangomint for free if you
        decide it is the right fit.
      </Text>
      <CloseButton>Sounds good!</CloseButton>
    </ReferralModal>
  );
};

export { ReferralModalTSB };
