import React from "react";
import { ReferralModal } from "./referralModal";

const ReferralModalCustomer = () => {
  const { Title, Text, CloseButton } = ReferralModal;
  return (
    <ReferralModal referralType="customer">
      <Title>Welcome, friend! 👋</Title>
      <Text>
        We’re so excited you were referred to Mangomint! Take a look around and start a trial when you’re ready. If you
        decide Mangomint is the right fit, we’ll gift you a free tech bundle for being a friend of a friend.
      </Text>
      <CloseButton>Sounds good!</CloseButton>
    </ReferralModal>
  );
};

export { ReferralModalCustomer };
